import React from 'react';
import { useState, useEffect } from 'react';
import backgroundImg from '../../assets/mybackground.jpeg';
import authorImg from '../../assets/user.png';

const RecentBlogs = () => {
    const [posts, setPosts] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(true);

    // Simulate fetching data from an API
    useEffect(() => {
        setTimeout(() => {
            // Replace this with your actual data fetching logic
            setPosts([
                // Example blog post data
                {
                    post: {
                        slug: 'example-post',
                        imageData: 'https://via.placeholder.com/400',
                        name: 'John Doe',
                        created_at: '2024-09-17T12:00:00Z',
                        title: 'Example Blog Post'
                    },
                    likeCount: 123
                },
                {
                    post: {
                        slug: 'example-post',
                        imageData: 'https://via.placeholder.com/400',
                        name: 'John Doe',
                        created_at: '2024-09-17T12:00:00Z',
                        title: 'Example Blog Post'
                    },
                    likeCount: 123
                },
                {
                    post: {
                        slug: 'example-post',
                        imageData: 'https://via.placeholder.com/400',
                        name: 'John Doe',
                        created_at: '2024-09-17T12:00:00Z',
                        title: 'Example Blog Post'
                    },
                    likeCount: 123
                }
                // Add more posts here
            ]);
            setShowLoadingModal(false);
        }, 2000); // Simulate a 2-second delay
    }, []);

    const funGetDateDiff = (date) => {
        // Function to calculate date difference
        const now = new Date();
        const then = new Date(date);
        const diff = Math.abs(now - then) / (1000 * 60 * 60 * 24);
        return `${Math.floor(diff)} days ago`;
    };

    return (
        <div>
            <div className="py-12 bg-white">
                <h1 className="text-center text-3xl font-semibold capitalize text-gray-800 lg:text-4xl">Recent Blogs</h1>
                <div className="mx-auto mt-6 flex justify-center">
                    <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                    <span className="inline-block h-1 w-40 rounded-full bg-red-700"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                    <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                </div>
                <h1 className="text-center text-2xl font-base capitalize text-gray-500 dark:text-gray-300 lg:text-2xl mt-6">
                    <span className="block xl:inline">Our Recent Blogs</span>
                </h1>
            </div>



            <div className="bg-cover w-full flex justify-center items-center" style={{ backgroundImage: `url(${backgroundImg})` }}>
                <div className="w-full bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                    <div className="w-12/12 mx-auto rounded-2xl bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                        {showLoadingModal && (
                            <div className="mx-auto pb-4">
                                <div className="p-4 bg-white border border-primary rounded-2xl">
                                    <div className="flex">
                                        <div className="mr-4 bg-gray-200 border border-gray-200 h-16 w-16 rounded animate-pulse"></div>
                                        <div className="space-y-1 flex flex-col w-full">
                                            <div className="flex w-full flex items-center">
                                                <div className="bg-gray-200 border border-gray-200 w-60 h-5 animate-pulse"></div>
                                                <div className="ml-4 bg-ternary w-12 h-5 animate-pulse"></div>
                                            </div>
                                            <div className="bg-gray-200 border border-gray-200 w-36 h-5 animate-pulse"></div>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-44 animate-pulse"></div>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex items-center justify-between">
                                        <div className="flex items-center gap-2">
                                            <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                            <span className="bg-tertiary h-1 w-1 rounded animate-pulse"></span>
                                            <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                        </div>
                                        <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {posts.map((data, index) => (
                                <article key={index} className="bg-white p-6 mb-6 shadow transition duration-300 group transform hover:-translate-y-2 hover:shadow-2xl rounded-2xl cursor-pointer border">
                                    <a target="_self" href={`/blog/${data.post.slug}`} className="absolute opacity-0 top-0 right-0 left-0 bottom-0"></a>
                                    <div className="relative mb-4 rounded-2xl">
                                        <img className="max-h-80 rounded-2xl w-full object-cover transition-transform duration-300 transform group-hover:scale-105" src={data.post.imageData} alt={data.post.title} />
                                        <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 text-red-700">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                            </svg>
                                            <span className="ml-1 text-sm text-slate-400">{data.likeCount}</span>
                                        </div>
                                        <a className="flex justify-center items-center bg-red-700 bg-opacity-80 z-10 absolute top-0 left-0 w-full h-full text-white rounded-2xl opacity-0 transition-all duration-300 transform group-hover:scale-105 text-xl group-hover:opacity-100" href={`/blog/${data.post.slug}`} target="_self" rel="noopener noreferrer">
                                            Read article
                                            <svg className="ml-2 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="flex justify-between items-center w-full pb-4 mb-auto">
                                        <div className="flex items-center">
                                            <div className="pr-3">
                                                <img className="h-12 w-12 rounded-full object-cover" src={authorImg} alt={data.post.name} />
                                            </div>
                                            <div className="flex flex-1">
                                                <div>
                                                    <p className="text-xs font-semibold ">{data.post.name}</p>
                                                    <p className="text-xs text-gray-500">{new Date(data.post.created_at).toLocaleDateString()}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <div className="text-sm flex items-center text-gray-500">
                                                {funGetDateDiff(data.post.created_at)}
                                                <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="font-medium text-base leading-8">
                                        <a href={`/blog/${data.post.slug}`} className="block relative group-hover:text-red-700 transition-colors duration-200">
                                            {data.post.title}
                                        </a>
                                    </h3>
                                </article>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentBlogs;
