import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bgImg from '../../assets/mybackground.jpeg';

const exampleWork = [
    {
        id: 1,
        workfile: 'https://via.placeholder.com/400',
        description: 'Design 1',
        slug: 'design-1',
        created_at: '2024-09-15T12:34:56Z'
    },
    {
        id: 2,
        workfile: 'https://via.placeholder.com/400',
        description: 'Design 2',
        slug: 'design-2',
        created_at: '2023-09-16T12:34:56Z'
    },
    {
        id: 3,
        workfile: 'https://via.placeholder.com/400',
        description: 'Design 3',
        slug: 'design-3',
        created_at: '2024-09-16T12:34:46Z'
    }
];

const ReactWork = () => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [imgData, setImgData] = useState('');

    const handleViewImageModal = (imageSrc) => {
        setImgData(imageSrc);
        setShowImageModal(true);
    };

    const handleCloseModal = () => {
        setShowImageModal(false);
        setImgData('');
    };

    const funGetDateDiff = (dateString) => {
        const now = new Date();
        const date = new Date(dateString);
        const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
        return `${diffInDays} days ago`;
    };

    return (
        <>
            <div className="py-12 bg-white">
                <h1 className="text-center text-3xl font-semibold capitalize text-gray-900 lg:text-4xl">
                    Creativity at Its Best
                </h1>
                <div className="mx-auto mt-6 flex justify-center">
                    <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                    <span className="inline-block h-1 w-40 rounded-full bg-red-700"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                    <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                </div>
                <h2 className="text-center text-2xl font-base capitalize text-gray-500 lg:text-2xl mt-6">
                    Our Recent Designs
                </h2>
            </div>

            <div
                className="bg-cover w-full flex justify-center items-center"
                style={{ backgroundImage: `url(${bgImg})` }}
            >
                <div className="w-full bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                    <div className="w-12/12 mx-auto rounded-2xl bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">

                        <div className="flex flex-wrap items-center overflow-x-auto overflow-y-hidden py-2 justify-center text-gray-800">
                            {exampleWork.map((data) => (
                                <div key={data.id} className="flex flex-col md:w-1/2 xl:w-1/3 p-4">
                                    <div className="bg-white shadow-md rounded-3xl p-4 border border-gray-100">
                                        <div className="flex-none lg:flex">
                                            <div className="h-full w-full lg:h-48 lg:w-48 lg:mb-0 mb-3">
                                                <img src={data.workfile} alt="Work Example" className="w-full object-cover lg:h-48 rounded-2xl" />
                                            </div>
                                            <div className="flex-auto ml-3 justify-evenly py-2">
                                                <div className="flex flex-wrap">
                                                    <div className="w-full flex-none text-xs text-blue-700 font-medium">Kikimo Developers</div>
                                                    <h3 className="flex-auto text-lg font-medium">{data.description}</h3>
                                                </div>
                                                <div className="flex py-4 text-sm text-gray-500">
                                                    <div className="flex-1 inline-flex items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                                        </svg>
                                                        <p>Nairobi, Kenya</p>
                                                    </div>
                                                    <div className="flex-1 inline-flex items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                        </svg>
                                                        <p>Posted {funGetDateDiff(data.created_at)}</p>
                                                    </div>
                                                </div>
                                                <div className="flex p-4 pb-2 border-t border-gray-200"></div>
                                                <div className="flex space-x-3 text-sm font-medium">
                                                <Link to={`/work/${data.id}`} className="mb-2 md:mb-0 bg-white px-4 py-2 shadow-sm tracking-wider border text-gray-600 rounded-full hover:bg-gray-100">
                                                    <span className="text-green-400 hover:text-green-500 rounded-lg">
                                                        <img src={data.workfile} className="w-5 h-5" alt="Icon" />
                                                    </span>
                                                    <span>#{data.slug}</span>
                                                    </Link>
                                                    <button onClick={() => handleViewImageModal(data.workfile)}
                                                        className="mb-2 md:mb-0 bg-red-600 px-5 py-2 shadow-sm tracking-wider text-white rounded-full hover:bg-gray-800">
                                                        View
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {showImageModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
                    <div className="bg-white p-5 rounded-xl shadow-lg max-w-lg relative">
                        <img className="rounded h-full" src={imgData} alt="Modal" />
                        <div className="p-3 text-center mt-2">
                            <button onClick={handleCloseModal}
                                className="bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:bg-red-600">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReactWork;
