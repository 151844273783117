import React, { useState } from 'react';
import logo from '../../assets/logo.png';
import user from '../../assets/user.png';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const toggleUserMenu = () => {
        setUserMenuOpen(!userMenuOpen);
    };

    return (
        <nav className="mx-2 px-2 sm:px-6 lg:px-8 sticky top-2 z-30 shadow backdrop-blur-2xl backdrop-saturate-200 bg-opacity-80 xl:block m-2 rounded-lg sm:lg:md:rounded-full">
            <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <button
                        type="button"
                        className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu"
                        aria-expanded={menuOpen}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <span className="absolute -inset-0.5"></span>
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className={`block h-6 w-6 ${menuOpen ? 'hidden' : 'block'}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        <svg
                            className={`hidden h-6 w-6 ${menuOpen ? 'block' : 'hidden'}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                        <img className="h-6" src={logo} alt="Logo" />
                    </div>
                    <div className="hidden sm:ml-6 sm:block">
                        <div className="flex space-x-4">
                            <a href="/" className="bg-red-600 text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">Home</a>
                            <a href="/services" className="text-gray-900 hover:bg-red-600 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Services</a>
                            <a href="/blogs" className="text-gray-900 hover:bg-red-600 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Blogs</a>
                            <a href="/ourwork" className="text-gray-900 hover:bg-red-600 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Our Work</a>
                            <a href="/shortposts" className="text-gray-900 hover:bg-red-600 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Short Posts</a>
                        </div>
                    </div>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button type="button" className="relative rounded-full bg-red-600 p-1 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                        <span className="absolute -inset-1.5"></span>
                        <span className="sr-only">View notifications</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                        </svg>
                    </button>

                    <div className="relative ml-3">
                        <div>
                            <button
                                type="button"
                                className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                id="user-menu-button"
                                aria-expanded={userMenuOpen}
                                aria-haspopup="true"
                                onClick={toggleUserMenu}
                            >
                                <span className="absolute -inset-1.5"></span>
                                <span className="sr-only">Open user menu</span>
                                <img className="h-8 w-8 rounded-full" src={user} alt="User" />
                            </button>
                        </div>

                        {userMenuOpen && (
                            <div className="absolute right-0 z-9 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                    <div className="py-2">
                                        <a href="/profile" className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                                            <p className="text-gray-600 text-sm mx-2">Profile</p>
                                        </a>
                                        <a href="/login" className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                                            <p className="text-gray-600 text-sm mx-2">Login</p>
                                        </a>
                                        <a href="/register" className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                                            <p className="text-gray-600 text-sm mx-2">Register</p>
                                        </a>
                                    </div>
                                    <a href="/logout" className="block bg-red-600 hover:bg-red-700 text-white text-center font-bold py-2">Logout</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {menuOpen && (
                <div className="sm:hidden" id="mobile-menu">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                        <a href="/" className="bg-red-600 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Home</a>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
