import React from 'react';
import bgImg from '../../assets/mybackground.jpeg'; 
import Card from '../Reuse/Card';

const servicesData = [
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="w-6 text-red-700"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                ></path>
            </svg>
        ),
        title: 'UI/UX Design',
        description: 'Our focus on user-centered design principles ensures that our users are always at the forefront of our minds as we create our designs.',
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="w-6 text-red-700"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                ></path>
            </svg>
        ),
        title: 'Web & App Development',
        description: 'Build an atmosphere that creates productivity in your organization and your company culture.',
    },
    {
        icon: (
            <svg
                className="w-6 text-red-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="3" y1="9" x2="21" y2="9"></line>
                <line x1="9" y1="21" x2="9" y2="9"></line>
            </svg>
        ),
        title: 'Graphical Design',
        description: 'We develop various types of creative content for advertisements, brochures, or magazines.',
    }
];

const Services = () => {
    return (
        <>
            <div className="py-12 bg-white">
                <h1 className="text-center text-3xl font-semibold capitalize text-gray-900 lg:text-4xl">
                    Our Services
                </h1>
                <div className="mx-auto mt-6 flex justify-center">
                    <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                    <span className="inline-block h-1 w-40 rounded-full bg-red-700"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                    <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                </div>
                <h1 className="text-center text-2xl font-base capitalize text-gray-500 lg:text-2xl mt-6">
                    <span className="block xl:inline">What We Offer To You !</span>
                </h1>
            </div>
            <div className="flex flex-wrap items-center overflow-x-auto overflow-y-hidden py-2 justify-center bg-white text-gray-800">
                {/* This div can be used for additional content if needed */}
            </div>
            <div
                id="services"
                className="bg-cover w-full flex justify-center items-center"
                style={{ backgroundImage: `url(${bgImg})` }}
            >
                <div className="w-full bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                    <div className="w-12/12 mx-auto rounded-2xl bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                        <div className="container mx-auto">
                            <div className="justify-items-center grid md:grid-cols-3 lg:grid-cols-3 gap-4 text-center py-4 mx-auto container">
                                {servicesData.map((service, index) => (
                                    <Card
                                        key={index}
                                        icon={service.icon}
                                        title={service.title}
                                        description={service.description}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;
