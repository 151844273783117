import React from 'react';

const Card = ({ icon, title, description }) => {
    return (
        <div className="component flex max-w-sm pt-16">
            <div className="w-full px-8 pb-8 shadow-lg rounded-lg bg-transparent border-2 border-red-700 text-center relative">
                <div className="absolute top-0 left-0 right-0">
                    <div className="inline-block bg-white border-2 border-red-700 rounded-full p-8 transform -translate-y-1/2">
                        {icon}
                    </div>
                </div>
                <div className="mt-20 uppercase text-transparent bg-clip-text bg-gradient-to-r from-red-700 via-red-400 to-red-700 font-bold">
                    {title}
                </div>
                <div className="mt-6 text-sm text-gray-900">
                    {description}
                </div>
            </div>
        </div>
    );
};

export default Card;
