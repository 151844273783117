import React, { useState, useEffect } from 'react';
import bgImg from '../../assets/mybackground.jpeg';
import user from '../../assets/user.png';

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch('/api/reviews');
                const data = await response.json();
                setReviews(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching reviews:', error);
                setLoading(false);
            }
        };

        fetchReviews();
    }, []);

    return (
        <><div className="py-12 bg-white">
            <h1 className="text-center text-3xl font-semibold capitalize text-gray-800 lg:text-4xl">
                Don't Just Take Our Word For It
            </h1>
            <div className="mx-auto mt-6 flex justify-center">
                <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                <span className="inline-block h-1 w-40 rounded-full bg-red-700"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
            </div>
            <h1 className="text-center text-2xl font-base capitalize text-gray-500 lg:text-2xl mt-6">
                <span className="block xl:inline">See what others are saying</span>
            </h1>
            <div
                class="flex flex-wrap items-center  overflow-x-auto overflow-y-hidden py-2 justify-center  bg-white text-gray-800">
            </div>
            {loading ? (
                <div className="mx-auto pb-0">
                    <div className="p-4 bg-white border border-primary rounded-md">
                        <div className="flex">
                            <div className="mr-4 bg-gray-200 border border-gray-200 h-16 w-16 rounded animate-pulse"></div>
                            <div className="space-y-1 flex flex-col w-full">
                                <div className="flex w-full flex items-center">
                                    <div className="bg-gray-200 border border-gray-200 w-60 h-5 animate-pulse"></div>
                                    <div className="ml-4 bg-ternary w-12 h-5 animate-pulse"></div>
                                </div>
                                <div className="bg-gray-200 border border-gray-200 w-36 h-5 animate-pulse"></div>
                                <div className="bg-gray-200 border border-gray-200 w-full h-44 animate-pulse"></div>
                            </div>
                        </div>
                        <div className="mt-4 flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                <span className="bg-tertiary h-1 w-1 rounded animate-pulse"></span>
                                <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                            </div>
                            <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    id="services"
                    className="bg-cover w-full flex justify-center items-center"
                    style={{ backgroundImage: `url(${bgImg})` }}
                >
                    <div class="w-full bg-white p-5  bg-opacity-40 backdrop-filter backdrop-blur-lg">
                        <div class="w-12/12 mx-auto rounded-2xl bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                            <section className="p-10 w-full mx-auto bg-transparent bg-no-repeat">
                                <div className="container mx-auto">
                                    <div className="items-center justify-center w-full mt-0 mb-0 lg:flex">
                                        {reviews.map((review, index) => (
                                            <div
                                                key={index}
                                                className="flex flex-col items-center justify-center w-full h-auto px-0 mx-0 mb-12 border-r border-transparent lg:w-1/3 lg:mb-0 lg:px-8 lg:mx-8 lg:border-gray-200"
                                            >
                                                <div className="flex items-center justify-center">
                                                    <div className="mr-4 overflow-hidden bg-gray-200 rounded-full shadow">
                                                        <img
                                                            className="h-12 w-12 rounded-full object-cover"
                                                            src={user}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="flex flex-col items-center justify-center">
                                                        <h4 className="text-sm font-semibold">{review.name}</h4>
                                                        <p className="text-sm text-gray-900">{review.role}</p>
                                                    </div>
                                                </div>
                                                <blockquote className="mt-4 text-center text-sm text-gray-900">
                                                    "{review.review}"
                                                </blockquote>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            )}
        </div></>
    );
};

export default Reviews;
