import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Header from './Header';
import Services from './Services';
import Reviews from './Reviews';
import RecentBlogs from './RecentBlogs';
import RecentWork from './RecentWork';

function HomePage () {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch('/api/users')
      .then((res) => res.json())
      .then((data) => setUsers(data));
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
        <Header />
        <Services />
        <Reviews />
        <RecentBlogs />
        <RecentWork />
    </div>
  );
}

export default HomePage ;